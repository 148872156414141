<template>
  <div class="main-card">
    <!-- 封面图 -->
    <div class="banner" :style="postCover">
    </div>
    <!-- 内容 -->
    <v-row>
      <div class="main-list">
        <div class="title">{{ this.$route.name }}</div>
        <!-- 资源状态 -->
        <div class="article-status-menu">
          <!-- <span>状态</span> -->
          <span @click="changeStatus('all')" :class="isActive('all')">全部</span>
          <span @click="changeStatus('public')" :class="isActive('public')">
            公开
          </span>
          <span @click="changeStatus('secret')" :class="isActive('secret')">
            私密
          </span>
        </div>
        <!-- 表格操作 -->
        <div class="operation-container">
          <el-button v-if="isDelete == 0" type="danger" size="small" icon="el-icon-delete"
            :disabled="articleIdList.length == 0" @click="updateIsDelete = true">
            批量删除
          </el-button>
          <el-button v-else type="danger" size="small" icon="el-icon-delete" :disabled="articleIdList.length == 0"
            @click="remove = true">
            批量删除
          </el-button>

          <a href="/share">
            <el-button type="primary" size="small" icon="el-icon-upload" style="margin-right:1rem">
              新建资源
            </el-button>
          </a>
          <!-- 条件筛选 -->
          <div style="margin-left:auto">
            <!-- 资源类型 -->
            <el-select clearable v-model="type" placeholder="请选择资源类型" size="small"
              style="margin-right:1rem;width: 180px;">
              <el-option v-for="item in typeList" :key="item.value" :label="item.desc" :value="item.value" />
            </el-select>
            <!-- 分类 -->
            <!-- 资源名 -->
            <el-input clearable v-model="keywords" prefix-icon="el-icon-search" size="small" placeholder="请输入资源名"
              style="width:200px" @keyup.enter.native="searchShares" />
            <el-button type="primary" size="small" icon="el-icon-search" style="margin-left:1rem" @click="searchShares">
              搜索
            </el-button>
          </div>
        </div>
        <!-- 表格展示 -->
        <el-table border :data="articleList" @selection-change="selectionChange" v-loading="loading">
          <!-- 表格列 -->
          <el-table-column type="selection" width="55" />
          <!-- 资源修改时间 -->
          <!-- 资源标题 -->
          <el-table-column prop="title" label="标题" align="center" />
          <!-- 资源分类 -->
          <el-table-column prop="type" label="类型" width="80" align="center">
            <template slot-scope="scope">
              <el-tag :type="articleType(scope.row.status).name">
                {{ articleType(scope.row.status).name }}
              </el-tag>
            </template>
          </el-table-column>
          <!-- 资源发表时间 -->
          <el-table-column prop="createTime" label="发表时间" width="130" align="center">
            <template slot-scope="scope">
              <i class="el-icon-time" style="margin-right:5px" />
              {{ scope.row.createTime | date }}
            </template>
          </el-table-column>

          <!-- 列操作 -->
          <el-table-column label="操作" align="center" width="150">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="editArticle(scope.row.id)">
                编辑
              </el-button>
              <el-popconfirm title="确定删除吗？" style="margin-left:10px" @confirm="updateArticleDelete(scope.row.id)">
                <el-button size="mini" type="danger" slot="reference">
                  删除
                </el-button>
              </el-popconfirm>
              <el-popconfirm title="确定恢复吗？" v-if="scope.row.isDelete == 1" @confirm="updateArticleDelete(scope.row.id)">
                <el-button size="mini" type="success" slot="reference">
                  恢复
                </el-button>
              </el-popconfirm>
              <el-popconfirm style="margin-left:10px" v-if="scope.row.isDelete == 1" title="确定彻底删除吗？"
                @confirm="deleteArticles(scope.row.id)">
                <el-button size="mini" type="danger" slot="reference">
                  删除
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination class="pagination-container" background @size-change="sizeChange" @current-change="currentChange"
          :current-page="current" :page-size="size" :total="count" :page-sizes="[10, 20]"
          layout="total, sizes, prev, pager, next, jumper" />
        <!-- 批量逻辑删除对话框 -->
        <el-dialog :visible.sync="updateIsDelete" width="30%">
          <div class="dialog-title-container" slot="title">
            <i class="el-icon-warning" style="color:#ff9900" />提示
          </div>
          <div style="font-size:1rem">是否删除选中项？</div>
          <div slot="footer">
            <el-button @click="updateIsDelete = false">取 消</el-button>
            <el-button type="primary" @click="updateArticleDelete(null)">
              确 定
            </el-button>
          </div>
        </el-dialog>
        <!-- 批量彻底删除对话框 -->
        <el-dialog :visible.sync="remove" width="30%">
          <div class="dialog-title-container" slot="title">
            <i class="el-icon-warning" style="color:#ff9900" />提示
          </div>
          <div style="font-size:1rem">是否彻底删除选中项？</div>
          <div slot="footer">
            <el-button @click="remove = false">取 消</el-button>
            <el-button type="primary" @click="deleteArticles(null)">
              确 定
            </el-button>
          </div>
        </el-dialog>
        <!-- 批量导出对话框 -->
        <el-dialog :visible.sync="isExport" width="30%">
          <div class="dialog-title-container" slot="title">
            <i class="el-icon-warning" style="color:#ff9900" />提示
          </div>
          <div style="font-size:1rem">是否导出选中资源？</div>
          <div slot="footer">
            <el-button @click="isExport = false">取 消</el-button>
            <el-button type="primary" @click="exportArticles(null)">
              确 定
            </el-button>
          </div>
        </el-dialog>
      </div>
    </v-row>

  </div>
</template>
  
<script>

import Vue from "vue";
import mavonEditor from "mavon-editor";
import "mavon-editor/dist/css/index.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";


Vue.use(ElementUI);
Vue.use(mavonEditor);


export default {
  created() {
    this.listSearchShares();
  },
  data: function () {
    return {
      loading: true,
      updateIsDelete: false,
      remove: false,
      typeList: [
        {
          type: 1,
          desc: "用户"
        },
        {
          type: 2,
          desc: "管理员"
        },
        {
          type: 3,
          desc: "匿名"
        }
      ],
      activeStatus: "all",
      articleList: [],
      articleIdList: [],
      categoryList: [],
      tagList: [],
      keywords: null,
      type: null,
      categoryId: null,
      tagId: null,
      isDelete: 0,
      isExport: false,
      status: null,
      current: 1,
      size: 10,
      count: 0
    };
  },
  methods: {
    selectionChange(articleList) {
      this.articleIdList = [];
      articleList.forEach(item => {
        this.articleIdList.push(item.id);
      });
    },
    searchShares() {
      this.current = 1;
      this.listSearchShares();
    },
    editArticle(id) {
      this.$router.push({ path: "/shares/" + id });
    },
    updateArticleDelete(id) {
      this.axios.delete("/api/share-resource/resource/" + id).then(({ data }) => {
        if (data.flag) {
          this.$notify.success({
            title: "成功",
            message: data.message
          });
          this.listSearchShares();
        } else {
          this.$notify.error({
            title: "失败",
            message: data.message
          });
        }
        this.updateIsDelete = false;
      });
    },
    deleteArticles(id) {
      this.axios.delete("/api/share-resource/resource/" + id).then(({ data }) => {
        if (data.flag) {
          this.$notify.success({
            title: "成功",
            message: data.message
          });
          this.listSearchShares();
        } else {
          this.$notify.error({
            title: "失败",
            message: data.message
          });
        }
        this.remove = false;
      });
    },
    sizeChange(size) {
      this.size = size;
      this.listSearchShares();
    },
    currentChange(current) {
      this.current = current;
      this.listSearchShares();
    },
    changeStatus(status) {
      switch (status) {
        case "all":
          this.isDelete = 0;
          this.status = null;
          break;
        case "public":
          this.isDelete = 0;
          this.status = 1;
          break;
        case "secret":
          this.isDelete = 0;
          this.status = 2;
          break;
        case "draft":
          this.isDelete = 0;
          this.status = 3;
          break;
        case "delete":
          this.isDelete = 1;
          this.status = null;
          break;
      }
      this.current = 1;
      this.activeStatus = status;
    },
    listSearchShares() {
      this.axios
        .post("/api/share-resource/resource", {
          current: this.current,
          size: this.size,
          status: this.status,
        })
        .then(({ data }) => {
          if (data.flag) {
            this.articleList = data.data.recordList;
            this.count = data.data.count;
            this.loading = false;
          } else {
            this.$notify.error({
              title: "失败",
              message: data.message
            });
            this.loading = false;
          }
        })
    },
    listCategories() {
      this.axios.get("/api/admin/categories/search").then(({ data }) => {
        this.categoryList = data.data;
      });
    },
    listTags() {
      this.axios.get("/api/admin/tags/search").then(({ data }) => {
        this.tagList = data.data;
      });
    }
  },
  watch: {
    type() {
      this.current = 1;
      this.listSearchShares();
    },
    categoryId() {
      this.current = 1;
      this.listSearchShares();
    },
    tagId() {
      this.current = 1;
      this.listSearchShares();
    },
    status() {
      this.current = 1;
      this.listSearchShares();
    },
    isDelete() {
      this.current = 1;
      this.listSearchShares();
    }
  },
  computed: {
    articleType() {
      return function (type) {
        var tagType = "";
        var name = "";
        switch (type) {
          case 1:
            tagType = "danger";
            name = "用户";
            break;
          case 2:
            tagType = "success";
            name = "管理员";
            break;
          case 3:
            tagType = "primary";
            name = "匿名";
            break;
        }
        return {
          tagType: tagType,
          name: name
        };
      };
    },
    isActive() {
      return function (status) {
        return this.activeStatus == status ? "active-status" : "status";
      };
    },
    postCover() {
      var cover = "";
      this.$store.state.blogInfo.pageList.forEach(item => {
        if (item.pageLabel == "post") {
          cover = item.pageCover;
        }
      });
      return "background: url(" + cover + ") center center / cover no-repeat";
    },
  }
};
</script>
  
<style scoped>
.main-list {
  margin: 342.5px auto 40px auto !important;
  margin: 0 auto;
}

.edit-card {
  margin: 372.5px auto 40px auto !important;
  width: 90%;
  min-width: 500px;
  min-height: 66px;

}

.operation-container {
  margin-top: 1.5rem;
}

.article-status-menu {
  font-size: 14px;
  margin-top: 40px;
  color: #999;
}

.article-status-menu span {
  margin-right: 24px;
}

.status {
  cursor: pointer;
}

.active-status {
  cursor: pointer;
  color: #333;
  font-weight: bold;
}

.article-cover {
  position: relative;
  width: 100%;
  height: 90px;
  border-radius: 4px;
}

.article-cover::after {
  content: "";
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.article-status-icon {
  color: #fff;
  font-size: 1.5rem;
  position: absolute;
  right: 1rem;
  bottom: 1.4rem;
}
</style>
  